import React from "react"

import Layout from "../../components/Layout"

export default function solutions() {
  return (
    <Layout>
      <div className="min-w-screen min-h-full flex flex-col">
        <div className="flex flex-col w-full h-full md:h-96 mx-auto md:flex-row md:items-center items-center justify-center mb-3 md:mb-0">
          <div className="w-full h-32 md:h-full py-5 md:w1/2 flex flex-col items-center justify-center bg-black">
            <h1 className="text-3xl md:text-5xl leading-normal mt-0 mb-2 text-white text-center flex">
              <span className="md:mb-2 font-bold">bewex</span>
              <span>Cargo</span>
            </h1>
          </div>
          <div className="w-full h-full md:w1/2 items-center justify-center px-6 md:px-10 py-3 flex flex-col">
            <h3 className="text-gray-800 text-sm md:text-base font-bold mb-3">
              Uma ferramenta completa de gestão para a sua empresa, conheça as
              principais características:
            </h3>
            <ul className="list-disc flex flex-col">
              <li className="text-gray-600 mb-1 text-xs md:text-sm">
                Geração e emissão de propostas comerciais, com cálculos
                automáticos de despesas
              </li>
              <li className="text-gray-600 mb-1 text-xs md:text-sm">
                Gestão de processos operacionais de todas as modalidades, com
                emissão de documentos e controle financeiro
              </li>
              <li className="text-gray-600 mb-1 text-xs md:text-sm">
                Gestão de contas à receber, contas à pagar e outras transações
                financeiras
              </li>
              <li className="text-gray-600 mb-1 text-xs md:text-sm">
                Emissão de NF de Serviços
              </li>
              <li className="text-gray-600 mb-1 text-xs md:text-sm">
                Relatórios de Fluxo de Caixa, Profit Operacional, DRE, Projeção
                Financeira, entre outros, com opções para visualizar valores na
                moeda local e em dólares
              </li>
              <li className="text-gray-600 mb-1 text-xs md:text-sm">
                Controle de acessos por filial e por transação
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col w-full h-full md:h-96 mx-auto md:flex-row md:items-center items-center justify-center">
          <div className="w-full h-32 md:h-full py-5 md:w1/2 flex flex-col items-center justify-center bg-orange">
            <h1 className="text-3xl md:text-5xl leading-normal mt-0 mb-2 text-white text-center flex">
              <span className="md:mb-2 font-bold">bewex</span>
              <span>Customs</span>
            </h1>
          </div>
          <div className="w-full h-32 md:h-full md:w1/2 items-center justify-center px-4 md:px-10 py-3 flex flex-col">
            <h3 className="text-gray-800 font-bold mb-3">EM BREVE</h3>
          </div>
        </div>

        {/* <div className="flex flex-col w-full h-full md:h-96 mx-auto md:flex-row md:items-center items-center justify-center">
          <div className="w-full h-32 md:h-full py-5 md:w1/2 flex flex-col items-center justify-center bg-sky-500">
            <h1 className="text-3xl md:text-5xl leading-normal mt-0 mb-2 text-white text-center flex">
              <span className="md:mb-2 font-bold">Tracking</span>
            </h1>
          </div>
          <div className="w-full h-32 md:h-full md:w1/2 items-center justify-center px-4 md:px-10 py-3 flex flex-col">
            <h3 className="text-gray-800 font-bold mb-3">EM BREVE</h3>
          </div>
        </div> */}
      </div>
    </Layout>
  )
}
